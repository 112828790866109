<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>分类参数</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 警告区域 -->
      <el-alert title="注意：只允许为第三级分类设置相关参数！" type="warning" show-icon :closable="false"></el-alert>
      <!-- 选择商品分类区域 -->
      <el-row>
        <el-col>
          <span>选择商品分类：</span>
          <!-- 级联选择器 -->
          <el-cascader
          v-model="selectedCatKeys"
          :options="catList"
          :props="selectCatProp"
          clearable
          filterable
          @change="catChanged">
          </el-cascader>
        </el-col>
      </el-row>
      <!-- tab 页签区域 -->
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <!-- 添加动态参数的面板 -->
        <el-tab-pane label="动态参数" name="many">
          <el-button type="primary" size="mini" :disabled="isBtnDisabled" @click="addParamsDialogVisible = true">添加参数</el-button>
          <!-- 动态参数表格 -->
          <el-table :data="manyParamsList" border style="width: 100%" :stripe="true">
            <!-- 动态参数展开列 -->
            <el-table-column type="expand">
              <template slot-scope="scope">
                <!-- tag标签 -->
                <el-tag v-for="(item, index) in scope.row.attr_vals" :key="index" closable :disable-transitions="false" @close="handleClose(scope.row, index)">
                  {{item}}
                </el-tag>
                <!-- 输入框输入的数据按下Enter键/光标失去焦点时确定 -->
                <el-input
                class="input-new-tag"
                v-if="scope.row.inputVisible"
                v-model="scope.row.inputValue"
                ref="saveTagInputRef"
                size="small"
                @keyup.enter.native="handleInputConfirm(scope.row)"
                @blur="handleInputConfirm(scope.row)">
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput(scope.row)">+ New Tag</el-button>
              </template>
            </el-table-column>
            <el-table-column type="index" label="#" align="center"></el-table-column>
            <el-table-column prop="attr_name" label="参数名称" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-tooltip content="修改参数" placement="top" :enterable="false">
                  <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditParamsDialog(scope.row.attr_id)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除参数" placement="top" :enterable="false">
                  <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteParams(scope.row.attr_id)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- 添加静态属性的面板 -->
        <el-tab-pane label="静态属性" name="only">
          <el-button type="primary" size="mini" :disabled="isBtnDisabled"  @click="addParamsDialogVisible = true">添加属性</el-button>
          <!-- 静态属性表格 -->
          <el-table :data="onlyParamsList" border style="width: 100%" :stripe="true">
             <!-- 静态属性展开列 -->
            <el-table-column type="expand">
              <template slot-scope="scope">
                <!-- tag标签 -->
                <el-tag v-for="(item, index) in scope.row.attr_vals" :key="index" closable :disable-transitions="false" @close="handleClose(scope.row, index)">
                  {{item}}
                </el-tag>
                <!-- 输入框输入的数据按下Enter键/光标失去焦点时确定 -->
                <el-input
                class="input-new-tag"
                v-if="scope.row.inputVisible"
                v-model="scope.row.inputValue"
                ref="saveTagInputRef"
                size="small"
                @keyup.enter.native="handleInputConfirm(scope.row)"
                @blur="handleInputConfirm(scope.row)">
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput(scope.row)">+ New Tag</el-button>
              </template>
            </el-table-column>
            <el-table-column type="index" label="#" align="center"></el-table-column>
            <el-table-column prop="attr_name" label="属性名称" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-tooltip content="修改属性" placement="top" :enterable="false">
                  <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditParamsDialog(scope.row.attr_id)"></el-button>
                </el-tooltip>
                <el-tooltip content="删除属性" placement="top" :enterable="false">
                  <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteParams(scope.row.attr_id)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
    </el-tabs>
    </el-card>
    <!-- 添加动态参数/静态属性对话框区域 -->
    <el-dialog :title="'添加' + getTitleText" :visible.sync="addParamsDialogVisible" width="50%" @close="addParamsDialogClosed">
      <el-form :model="addParamsForm" :rules="paramsFormRules" ref="addParamsFormRef" label-width="100px">
        <el-form-item :label="getTitleText" prop="attr_name">
          <el-input v-model.trim="addParamsForm.attr_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="addParamsDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="addParams">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改动态参数/静态属性对话框区域 -->
    <el-dialog :title="'修改' + getTitleText" :visible.sync="editParamsDialogVisible" width="50%" @close="editParamsDialogClosed">
      <el-form :model="editParamsForm" :rules="paramsFormRules" ref="editParamsFormRef" label-width="100px">
        <el-form-item :label="getTitleText" prop="attr_name">
          <el-input v-model.trim="editParamsForm.attr_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="editParamsDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="editParams">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      // 分类列表
      catList: [],
      // 选择分类的级联选择器配置
      selectCatProp: {
        // 鼠标悬停展开次级分类
        expandTrigger: 'hover',
        // 可以选择任意一级的选项
        checkStrictly: true,
        label: 'cat_name',
        value: 'cat_id',
        children: 'children'
      },
      // 保存选择的分类id，双向绑定到级联选择器
      selectedCatKeys: [],
      // 当前激活的tab页签名称
      activeName: 'many',
      // 动态参数列表
      manyParamsList: [],
      // 静态属性列表
      onlyParamsList: [],
      // 控制添加对话框显示/隐藏
      addParamsDialogVisible: false,
      // 添加动态参数/静态属性的表单数据
      addParamsForm: {
        attr_name: ''
      },
      // 控制修改对话框显示/隐藏
      editParamsDialogVisible: false,
      // 修改动态参数/静态属性的表单数据
      editParamsForm: {
        attr_name: '',
        attr_id: 0
      },
      // 填写表单的预校验规则
      paramsFormRules: {
        attr_name: [
          { required: true, message: '请输入参数名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getCateList()
  },
  methods: {
    // 获取分类列表
    async getCateList () {
      const { data: res } = await this.$http.get('categories')
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取分类列表失败',
          center: true,
          duration: 1000
        })
      }
      this.catList = res.data
    },
    // 获取分类参数列表
    async getParamsData () {
      if (!this.getCateId) return
      const { data: res } = await this.$http.get(`categories/${this.getCateId}/attributes`, {
        params: { sel: this.activeName }
      })
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取分类参数列表失败',
          center: true,
          duration: 1000
        })
      }
      // 展开列数据处理
      res.data.forEach(item => {
        item.attr_vals = item.attr_vals ? item.attr_vals.split(',') : []
        item.inputVisible = false
        item.inputValue = ''
      })
      if (this.activeName === 'many') {
        // 是动态参数
        this.manyParamsList = res.data
      } else {
        // 是静态属性
        this.onlyParamsList = res.data
      }
    },
    // 级联选择器选项变化时触发的事件
    catChanged () {
      // 证明选择的不是三级分类
      if (this.selectedCatKeys.length !== 3) {
        this.selectedCatKeys = [] // 清空分类选择
        this.manyParamsList = [] // 清空动态参数表格数据
        this.onlyParamsList = [] // 清空静态属性表格数据
        return
      }
      // 选择的是三级分类，那就获取该级参数列表
      this.getParamsData()
    },
    // tab页签变化时触发的事件
    handleTabClick () {
      // tab页签切换时也要获取该级对应的参数列表
      this.getParamsData()
    },
    // 删除参数
    async deleteParams (attrId) {
      const result = await this.$confirm('此操作将永久删除该参数/属性, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // 确定删除返回confirm，取消删除返回cancel
      if (result !== 'confirm') {
        return this.$message({
          type: 'info',
          message: '已取消删除操作',
          center: true,
          duration: 1000
        })
      }
      console.log(this.getCateId, attrId)
      const { data: res } = await this.$http.delete(`categories/${this.getCateId}/attributes/${attrId}`)
      console.log(res)
      // 删除参数失败
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '删除参数/属性失败',
          center: true,
          duration: 1000
        })
      }
      // 删除参数成功
      this.$message({
        type: 'success',
        message: '删除参数/属性成功',
        center: true,
        duration: 1000
      })
      // 更新参数列表
      this.getParamsData()
    },
    // 关闭添加对话框触发的事件
    addParamsDialogClosed () {
      // 重置添加表单
      this.$refs.addParamsFormRef.resetFields()
    },
    // 点击按钮，添加动态参数/静态属性
    addParams () {
      this.$refs.addParamsFormRef.validate(async (valid) => {
        if (!valid) return false
        // 通过表单预校验，提交数据到后台
        const { data: res } = await this.$http.post(`categories/${this.getCateId}/attributes`, {
          attr_name: this.addParamsForm.attr_name,
          attr_sel: this.activeName
        })
        console.log(res)
        if (res.meta.status !== 201) {
          return this.$message({
            type: 'error',
            message: `添加${this.getTitleText}失败`,
            center: true,
            duration: 1000
          })
        }
        this.$message({
          type: 'success',
          message: `添加${this.getTitleText}成功`,
          center: true,
          duration: 1000
        })
        // 更新参数列表
        this.getParamsData()
        // 隐藏添加对话框
        this.addParamsDialogVisible = false
      })
    },
    // 关闭修改对话框触发的事件
    editParamsDialogClosed () {
      // 重置修改表单
      this.$refs.editParamsFormRef.resetFields()
    },
    // 显示修改对话框
    async showEditParamsDialog (attrId) {
      // 获取原本的参数名称
      const { data: res } = await this.$http.get(`categories/${this.getCateId}/attributes/${attrId}`)
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: `获取${this.getTitleText}失败`,
          center: true,
          duration: 1000
        })
      }
      this.editParamsForm.attr_name = res.data.attr_name
      this.editParamsForm.attr_id = res.data.attr_id
      this.editParamsDialogVisible = true
    },
    // 点击按钮，修改参数提交到后台
    editParams () {
      this.$refs.editParamsFormRef.validate(async (valid) => {
        if (!valid) return false
        // 通过表单预校验，提交数据到后台
        const { data: res } = await this.$http.put(`categories/${this.getCateId}/attributes/${this.editParamsForm.attr_id}`, {
          attr_name: this.editParamsForm.attr_name,
          attr_sel: this.activeName
        })
        console.log(res)
        if (res.meta.status !== 200) {
          return this.$message({
            type: 'error',
            message: `修改${this.getTitleText}失败`,
            center: true,
            duration: 1000
          })
        }
        this.$message({
          type: 'success',
          message: `修改${this.getTitleText}成功`,
          center: true,
          duration: 1000
        })
        // 更新参数列表
        this.getParamsData()
        // 隐藏修改对话框
        this.editParamsDialogVisible = false
      })
    },
    // 控制展开列内标签的输入框显示
    showInput (row) {
      row.inputVisible = true
      // $nextTick函数的作用，当页面重新渲染之后才执行回调函数
      this.$nextTick(_ => {
        // 输入框获得焦点
        this.$refs.saveTagInputRef.$refs.input.focus()
      })
    },
    async saveAttr (row) {
      const { data: res } = await this.$http.put(`categories/${this.getCateId}/attributes/${row.attr_id}`, {
        attr_name: row.attr_name,
        attr_sel: row.attr_sel,
        attr_vals: row.attr_vals.join(',')
      })
      console.log(res)
      if (res.meta.status !== 200) {
        this.$message({
          type: 'error',
          message: '修改参数失败',
          center: true,
          duration: 1000
        })
        return
      }
      this.$message({
        type: 'success',
        message: '修改参数成功',
        center: true,
        duration: 1000
      })
    },
    // 添加标签输入数据确定时触发的事件
    handleInputConfirm (row) {
      // 去除头部和末尾输入的空格
      if (!row.inputValue.trim()) {
        // 重置数据
        row.inputValue = ''
        row.inputVisible = false
        return
      }
      row.attr_vals.push(row.inputValue)
      // 重置数据
      row.inputValue = ''
      row.inputVisible = false
      // 把修改添加的数据提交到后台
      this.saveAttr(row)
    },
    // 删除标签时触发的事件
    handleClose (row, index) {
      row.attr_vals.splice(index, 1)
      this.saveAttr(row)
    }
  },
  computed: {
    // 添加参数/属性的按钮是否禁用，返回true则表示要禁用
    isBtnDisabled () {
      if (this.selectedCatKeys.length !== 3) {
        return true
      }
      return false
    },
    // 当前选择的三级分类的id
    getCateId () {
      if (this.selectedCatKeys.length === 3) {
        return this.selectedCatKeys[this.selectedCatKeys.length - 1]
      }
      return null
    },
    // 添加对话框的标题文本
    getTitleText () {
      return this.activeName === 'many' ? '动态参数' : '静态属性'
    }
  }
}
</script>
<style lang="less" scoped>
  .el-alert{
    margin-bottom: 16px;
  }
  .el-tag{
    margin: 10px;
  }
  .input-new-tag{
    width: 120px;
  }
</style>
