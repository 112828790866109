<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 搜索区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入搜索内容" clearable v-model.trim="queryInfo.query" @clear="getUsersList">
            <el-button slot="append" icon="el-icon-search" @click="queryInfo.query?getUsersList():''"></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true">添加用户</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="usersList" border style="width: 100%" :stripe="true">
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="username" label="姓名" align="center"></el-table-column>
        <el-table-column prop="email" label="邮箱" align="center"></el-table-column>
        <el-table-column prop="mobile" label="电话" align="center"></el-table-column>
        <el-table-column prop="role_name" label="角色" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
          <!-- 作用域插槽新写法 -->
          <template v-slot:default="slotProps">
            <el-switch v-model="slotProps.row.mg_state" active-text="可用" inactive-text="禁用" @change="userStateChange(slotProps.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <!-- 作用域插槽旧写法 -->
          <template slot-scope="scope">
            <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditUserDialog(scope.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteUser(scope.row.id)"></el-button>
             </el-tooltip>
            <el-tooltip content="分配角色" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" @click="showSetUserRoleDialog(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[1, 2, 3, 5, 10]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="25%" :center="true" @close="addDialogClosed">
      <el-form :model="addUserForm" :rules="userFormRules" ref="addFormRef" label-width="80px" class="add-user-form">
        <el-form-item label="用户名" prop="username">
          <el-input v-model.trim="addUserForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model.trim="addUserForm.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPassword">
          <el-input type="password" v-model.trim="addUserForm.checkPassword"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model.trim="addUserForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model.trim="addUserForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="addDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑修改用户对话框 -->
    <el-dialog title="修改用户" :visible.sync="editDialogVisible" width="25%" :center="true" @close="editDialogClosed">
      <el-form :model="editUserForm" :rules="userFormRules" ref="editFormRef" label-width="80px" class="edit-user-form">
        <el-form-item label="用户名">
          <el-input v-model.trim="editUserForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model.trim="editUserForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model.trim="editUserForm.mobile"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="editDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="分配角色" :visible.sync="setRoleDialogVisible" width="25%" @close="setRoleDialogClosed">
      <div>
        <p>当前用户：{{userInfo.username}}</p>
        <p>当前角色：{{userInfo.role_name}}</p>
        <el-select v-model="userRoleId" placeholder="请选择用户角色">
          <el-option :label="item.roleName" :value="item.id" v-for="item in rolesList" :key="item.id"></el-option>
        </el-select>
      </div>
      <span slot="footer">
        <el-button @click="setRoleDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="setUserRole">确 定</el-button>
      </span>
      </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    // 手机号码校验规则
    const checkMobile = (rule, value, callback) => {
      // 手机号码验证正则
      const MobileReg = /^1[3456789]\d{9}$/
      // const reg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/
      // const reg = /^1(3\d|4[5-8]|5[0-35-9]|6[567]|7[01345-8]|8\d|9[025-9])\d{8}$/
      if (!(MobileReg.test(value))) return callback(new Error('请输入合法的手机号码'))
      callback()
    }
    // 邮箱校验规则
    const checkEmail = (rule, value, callback) => {
      // 邮箱验证正则
      const EmailReg = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (!(EmailReg.test(value))) return callback(new Error('请输入合法的邮箱'))
      callback()
    }
    // 密码校验规则
    const validatePassword = (rule, value, callback) => {
      if (this.addUserForm.checkPassword !== '') {
        this.$refs.addFormRef.validateField('checkPassword')
      }
      callback()
    }
    const validateCheckPassword = (rule, value, callback) => {
      if (value !== this.addUserForm.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      // 获取用户列表定义的参数对象
      queryInfo: {
        query: '', // 搜索用户参数
        pagenum: 1, // 显示第几页数据
        pagesize: 2 // 每页显示数据条数
      },
      usersList: [], // 保存用户数据
      total: 0, // 保存用户总人数
      addDialogVisible: false, // 控制添加用户对话框显示/隐藏
      editDialogVisible: false, // 控制修改用户对话框显示/隐藏
      setRoleDialogVisible: false, // 控制分配用户角色对话框显示/隐藏
      addUserForm: { // 保存添加用户表单数据
        username: '',
        password: '',
        checkPassword: '',
        email: '',
        mobile: '',
        rid: ''
      },
      editUserForm: {}, // 保存编辑用户表单数据
      userInfo: {}, // 保存分配角色用户信息
      rolesList: [], // 保存角色列表
      userRoleId: '', // 保存分配的角色id
      userFormRules: { // 用户表单数据预校验规则
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validateCheckPassword, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getUsersList()
  },
  methods: {
    async getUsersList () {
      const { data: res } = await this.$http.get('users', { params: this.queryInfo })
      console.log(res)
      // 获取用户列表失败
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: res.meta.msg || '获取用户列表失败',
          center: true,
          duration: 1000
        })
      }
      // 获取用户列表成功
      this.usersList = res.data.users
      this.total = res.data.total
    },
    // 设置用户状态
    async userStateChange (userInfo) {
      const { data: res } = await this.$http.put(`users/${userInfo.id}/state/${userInfo.mg_state}`)
      console.log(res)
      // 设置用户状态失败
      if (res.meta.status !== 200) {
        userInfo.mg_state = !userInfo.mg_state
        return this.$message({
          type: 'error',
          message: '设置用户状态失败',
          center: true,
          duration: 1000
        })
      }
      // 设置用户状态成功
      this.$message({
        type: 'success',
        message: '设置用户状态成功',
        center: true,
        duration: 1000
      })
    },
    // 用户列表展示操作
    handleSizeChange (val) {
      // 改变列表每页展示的数据条数
      this.queryInfo.pagesize = val
      this.getUsersList()
    },
    handleCurrentChange (val) {
      // 改变当前展示的页码数
      this.queryInfo.pagenum = val
      this.getUsersList()
    },
    // 添加用户对话框操作
    // 关闭添加用户对话框时重置表单
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 添加用户提交操作
    addUser () {
      // 前端表单规则验证处理
      this.$refs.addFormRef.validate(async (valid) => {
        // 不通过，啥也不干
        if (!valid) return false
        // 通过表单规则验证，则把数据提交到后台
        const { data: res } = await this.$http.post('users', {
          username: this.addUserForm.username,
          password: this.addUserForm.password,
          email: this.addUserForm.email,
          mobile: this.addUserForm.mobile
        })
        console.log(res)
        // 添加用户失败
        if (res.meta.status !== 201) {
          return this.$message({
            type: 'error',
            message: res.meta.msg || '添加用户失败',
            center: true,
            duration: 1000
          })
        }
        // 添加用户成功
        this.$message({
          type: 'success',
          message: '添加用户成功',
          center: true,
          duration: 1000
        })
        // 隐藏对话框
        this.addDialogVisible = false
        // 更新用户列表
        this.getUsersList()
      })
    },
    // 编辑用户对话框相关操作
    editDialogClosed () {
      // 关闭编辑对话框时重置对话框
      this.$refs.editFormRef.resetFields()
    },
    // 显示编辑用户对话框
    async showEditUserDialog (id) {
      // users/:id
      // 获取要编辑的用户信息
      const { data: res } = await this.$http.get(`users/${id}`)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取用户信息失败，无法编辑',
          center: true,
          duration: 1000
        })
      }
      this.editUserForm = res.data
      // 打开编辑对话框
      this.editDialogVisible = true
    },
    // 编辑用户提交操作
    editUser () {
      // 提交数据之前预校验
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return false
        // 预校验通过
        const { data: res } = await this.$http.put(`users/${this.editUserForm.id}`, {
          email: this.editUserForm.email,
          mobile: this.editUserForm.mobile
        })
        console.log(res)
        if (res.meta.status !== 200) {
          return this.$message({
            type: 'error',
            message: '更新用户信息失败',
            center: true,
            duration: 1000
          })
        }
        // 修改用户成功
        this.$message({
          type: 'success',
          message: '更新用户信息成功',
          center: true,
          duration: 1000
        })
        // 隐藏对话框
        this.editDialogVisible = false
        // 更新用户列表
        this.getUsersList()
      })
    },
    // 删除用户
    async deleteUser (id) {
      const result = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // 确定删除返回confirm，取消删除返回cancel
      if (result !== 'confirm') {
        return this.$message({
          type: 'info',
          message: '已取消删除操作',
          center: true,
          duration: 1000
        })
      }
      const { data: res } = await this.$http.delete(`users/${id}`)
      console.log(res)
      // 删除用户失败
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '删除用户失败',
          center: true,
          duration: 1000
        })
      }
      // 删除用户成功
      this.$message({
        type: 'success',
        message: '删除用户成功',
        center: true,
        duration: 1000
      })
      // 更新用户列表
      this.queryInfo.pagenum = 1 // 跳回第一页显示
      this.getUsersList()
    },
    // 分配用户角色相关操作
    setRoleDialogClosed () {
      // 关闭分配用户角色对话框时重置数据
      this.userInfo = {}
      this.userRoleId = ''
    },
    // 打开分配用户角色对话框，并获取用户数据和角色数据
    async showSetUserRoleDialog (userInfo) {
      this.userInfo = userInfo
      const { data: res } = await this.$http.get('roles')
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取角色数据失败',
          center: true,
          duration: 1000
        })
      }
      this.rolesList = res.data
      this.setRoleDialogVisible = true
    },
    // 点击，分配角色
    async setUserRole () {
      const { data: res } = await this.$http.put(`users/${this.userInfo.id}/role`, {
        rid: this.userRoleId
      })
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '分配用户角色失败',
          center: true,
          duration: 1000
        })
      }
      // 分配用户角色成功
      this.$message({
        type: 'success',
        message: '分配用户角色成功',
        center: true,
        duration: 1000
      })
      // 隐藏对话框
      this.setRoleDialogVisible = false
      // 更新用户列表
      this.getUsersList()
    }
  }
}
</script>
<style lang="less" scoped>
  .add-user-form{
    padding: 0 25px;
  }
  .edit-user-form{
    padding: 0 46px 0 20px;
  }
  .select-role-form{
    padding: 0 50px;
  }
</style>
