<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品分类</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 添加分类按钮 -->
      <el-row>
        <el-col :span="2">
          <el-button type="primary" @click="showAddCatDialog">添加分类</el-button>
        </el-col>
      </el-row>
      <!-- 表格区域 -->
      <tree-table
      class="tree-table-box"
      :data="catList"
      :columns="columns"
      :stripe="false"
      :border="true"
      :selection-type="false"
      :expand-type="false"
      show-index
      index-text="#"
      :show-row-hover="false">
        <template slot="isok" slot-scope="scope">
          <i class="el-icon-success" style="color: lightgreen" v-if="scope.row.cat_deleted === false"></i>
          <i class="el-icon-error" style="color: red" v-else></i>
        </template>
        <template slot="sort" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.cat_level === 0">一级</el-tag>
          <el-tag type="success" size="mini" v-else-if="scope.row.cat_level === 1">二级</el-tag>
          <el-tag type="warning" size="mini" v-else-if="scope.row.cat_level === 2">三级</el-tag>
        </template>
        <template slot="opt" slot-scope="scope">
          <el-tooltip content="编辑分类" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditCatDialog(scope.row.cat_id)"></el-button>
            </el-tooltip>
            <el-tooltip content="删除分类" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteCat(scope.row.cat_id)"></el-button>
             </el-tooltip>
        </template>
      </tree-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageNumChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[1, 2, 3, 5, 10]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加分类对话框 -->
    <el-dialog title="添加分类" :visible.sync="addCatDialogVisible" width="50%" @close="addCatDialogClosed">
      <el-form :model="addCatForm" :rules="catFormRules" ref="addCatFormRef" label-width="100px">
        <el-form-item label="分类名称：" prop="cat_name">
          <el-input v-model.trim="addCatForm.cat_name"></el-input>
        </el-form-item>
        <el-form-item label="父级分类：">
          <!-- 级联选择器区域 -->
          <!-- options：可选项数据源 -->
          <!-- props：数据配置选项 -->
          <el-cascader
          v-model="selectedCatKeys"
          :options="parentCatList"
          :props="addCatProp"
          clearable
          change-on-select="true"
          @change="parentCatChanged">
          </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="addCatDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="addCat">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑分类对话框 -->
    <el-dialog title="编辑分类" :visible.sync="editCatDialogVisible" width="50%">
      <span slot="footer">
        <el-button @click="editCatDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="addCat">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      // 查询分类条件
      queryInfo: {
        type: 3, // 分类层次
        pagenum: 1,
        pagesize: 5
      },
      // 分类列表
      catList: [],
      // 父级分类列表
      parentCatList: [],
      // 分类总数
      total: 0,
      // 控制添加分类对话框显示/隐藏
      addCatDialogVisible: false,
      // 控制编辑分类对话框显示/隐藏
      editCatDialogVisible: false,
      // 添加分类表单数据
      addCatForm: {
        // 分类名称
        cat_name: '',
        // 父级分类的id
        cat_pid: 0,
        // 分类等级默认为一级
        cat_level: 0
      },
      // 保存选择的父级分类id
      selectedCatKeys: [],
      // 分类表单数据校验规则
      catFormRules: {
        cat_name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ]
      },
      // tree-table表格每列的数据定义
      columns: [
        {
          label: '分类名称',
          prop: 'cat_name'
        },
        { // 自定义模板列
          label: '是否有效',
          prop: 'cat_deleted',
          type: 'template',
          template: 'isok'
        },
        {
          label: '排序',
          prop: 'cat_level',
          type: 'template',
          template: 'sort'
        },
        {
          label: '操作',
          type: 'template',
          template: 'opt'
        }
      ],
      // 添加分类的级联选择器配置
      addCatProp: {
        // 鼠标悬停展开次级分类
        expandTrigger: 'hover',
        label: 'cat_name',
        value: 'cat_id',
        children: 'children'
      }
    }
  },
  created () {
    this.getCateList()
  },
  methods: {
    // 获取分类列表
    async getCateList () {
      const { data: res } = await this.$http.get('categories', { params: this.queryInfo })
      // console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取分类列表失败',
          center: true,
          duration: 1000
        })
      }
      this.catList = res.data.result
      this.total = res.data.total
    },
    // 每页显示的条数改变时触发的事件
    pageSizeChange (newPagesize) {
      this.queryInfo.pagesize = newPagesize
      this.getCateList()
    },
    // 页码数改变时触发的事件
    pageNumChange (newPagenum) {
      this.queryInfo.pagenum = newPagenum
      this.getCateList()
    },
    // 添加分类相关操作
    // 获取父级分类，总共三级分类，所以要获取二级分类
    async getParentCatList () {
      const { data: res } = await this.$http.get('categories', { params: { type: 2 } })
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取父级分类列表失败',
          center: true,
          duration: 1000
        })
      }
      this.parentCatList = res.data
    },
    showAddCatDialog () {
      // 获取父级分类数据
      this.getParentCatList()
      this.addCatDialogVisible = true
    },
    // 分类选择项发生变化触发的事件
    parentCatChanged () {
      // 选择了父级分类时，会把所有分类节点的值(数组)保存在selectedCatKeys中(v-model)
      // selectedCatKeys.length 大于 0，说明选择了父级分类
      // 反之，就说明没有选择任何父级分类
      if (this.selectedCatKeys.length > 0) {
        // 获取最后一级父级分类的id
        this.addCatForm.cat_pid = this.selectedCatKeys[this.selectedCatKeys.length - 1]
        // 设置分类等级
        this.addCatForm.cat_level = this.selectedCatKeys.length
      } else {
        // 没有选择父级分类时的父级默认为0，等级为一级(0为一级)
        this.addCatForm.cat_pid = 0
        this.addCatForm.cat_level = 0
      }
    },
    // 提交添加分类
    addCat () {
      this.$refs.addCatFormRef.validate(async (valid) => {
        if (!valid) return false
        // 表单预校验通过
        console.log(this.addCatForm)
        const { data: res } = await this.$http.post('categories', this.addCatForm)
        console.log(res)
        if (res.meta.status !== 201) {
          return this.$message({
            type: 'error',
            message: '添加分类失败',
            center: true,
            duration: 1000
          })
        }
        this.$message({
          type: 'success',
          message: '添加分类成功',
          center: true,
          duration: 1000
        })
        // 更新分类列表
        this.getCateList()
        this.addCatDialogVisible = false
      })
    },
    // 关闭添加分类对话框触发的事件
    addCatDialogClosed () {
      // 重置数据
      this.$refs.addCatFormRef.resetFields()
      this.selectedCatKeys = []
      this.addCatForm.cat_pid = 0
      this.addCatForm.cat_level = 0
    },
    showEditCatDialog (id) {
      this.editCatDialogVisible = true
    },
    // 删除分类
    async deleteCat (id) {
      console.log(id)
      const result = await this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // 确定删除返回confirm，取消删除返回cancel
      if (result !== 'confirm') {
        return this.$message({
          type: 'info',
          message: '已取消删除操作',
          center: true,
          duration: 1000
        })
      }
      const { data: res } = await this.$http.delete(`categories/${id}`)
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '删除分类失败',
          center: true,
          duration: 1000
        })
      }
      this.$message({
        type: 'success',
        message: '删除分类成功',
        center: true,
        duration: 1000
      })
      // 更新分类列表
      this.getCateList()
    }
  }
}
</script>
<style lang="less" scoped>
  .tree-table-box{
    margin-top: 16px;
  }
  .el-cascader{
    width: 100%;
  }
</style>
